





































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import {
  BankNameDto,
  BankTypeDto,
  DataDictionaryDto,
  FundDto,
  FundDtoPagedResultDto,
  MerchantBankAccountDto,
  MerchantDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "MerchantBankAccountList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class MerchantBankAccountList extends Vue {
  typeList: any = [];
  SyncStatusList = [
    {
      value: false,
      label: "未同步",
    },
    {
      value: true,
      label: "已同步",
    },
  ];
  fundList: FundDto[] = [];
  // bankTypeList: BankTypeDto[] = [];
  bankTypeList: DataDictionaryDto[] = [];
  bankNameList: BankNameDto[] = [];
  queryForm = {};
  isCreate = true;
  isShow = false;
  merchantId?: number = 0;
  form: MerchantBankAccountDto = {
    id: 0,
    merchantId: undefined,
    bankAccountCode: undefined,
    bankTypePK: undefined,
    bankName: undefined,
    bankAccountName: undefined,
  };
  btnDisabled = false;

  created() {
    this.fetchEnumType();
    this.fetchFundList();
    this.fetchBankTypeList();
    this.fetchBankNameList();
  }

  async fetchFundList() {
    await api.fund.getAll({}).then((res: FundDtoPagedResultDto) => {
      this.fundList = res.items ?? [];
    });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchBankTypeList() {
    await api.dataDictionary
      .getDataDictionaryByKey({key: "BankType"})
      .then((res) => {
        this.bankTypeList = res;
      });
  }

  // async fetchBankTypeList() {
  //   await api.bankType.getAll({}).then((res) => {
  //     this.bankTypeList = res?.items!;
  //   });
  // }

  async fetchBankNameList() {
    await api.bankName.getAll({}).then((res) => {
      this.bankNameList = res.items!;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.$route.query.merchantId) {
      this.form.merchantId = params.merchantId = Number(
        this.$route.query.merchantId
      );
    }
    return api.merchantBankAccount.getAll(params);
  }

  // 新增
  handleCreate() {
    this.isCreate = true;
    this.isShow = true;
    this.btnDisabled = false;
    this.form.id = undefined;
    this.form.bankAccountCode = "";
    this.form.bankName = "";
    this.form.bankAccountName = undefined;
    this.form.bankTypePK = undefined;
    this.form.bankNamePK = undefined;
  }

  // 修改
  handleEdit(id: number) {
    this.isCreate = false;
    this.btnDisabled = false;
    api.merchantBankAccount.get({id: id}).then((res: any) => {
      if (res) {
        this.form = {...res};
      }
    });
    this.isShow = true;
  }

  // 删除
  handleDelete(row: MerchantBankAccountDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.merchantBankAccount.delete({id: row.id}).then((res: any) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  handleEnable(row: MerchantDto) {
    this.$confirm("你确定启用吗?", "提示").then(async () => {
      await api.merchantBankAccount
        .enableOrDisable({body: {id: row.id}})
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "启用成功!",
          });
        });
    });
  }

  handleDisable(row: MerchantDto) {
    this.$confirm("你确定禁用吗?", "提示").then(async () => {
      await api.merchantBankAccount
        .enableOrDisable({body: {id: row.id}})
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "禁用成功!",
          });
        });
    });
  }

  handleSync(row: MerchantDto) {
    this.$confirm("你确定同步至用友吗?", "提示").then(async () => {
      await api.merchantBankAccount
        .syncBankAccountToNc({id: row.id})
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "同步成功!",
          });
        });
    });
  }

  cancel() {
    this.$router.back();
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.merchantBankAccount
            .update({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        } else {
          await api.merchantBankAccount
            .create({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        }
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  roleRule = {
    payeeName: [
      {
        required: true,
        message: "收款人姓名必填",
        trigger: "blur",
      },
      {
        max: 100,
        message: "收款人姓名最长不可超过100个字符",
        trigger: "blur",
      },
    ],
    payeePhone: [
      {
        required: true,
        message: "收款人电话必填",
        trigger: "blur",
      },
      {
        max: 100,
        message: "收款人电话最长不可超过100个字符",
        trigger: "blur",
      },
    ],
    bankAccountCode: [
      {
        required: true,
        message: "银行账号必填",
        trigger: "blur",
      },
      {
        max: 255,
        message: "银行账号最长不可超过255个字符",
        trigger: "blur",
      },
    ],
    bankAccountName: [
      {
        required: true,
        message: "户名必填",
        trigger: "blur",
      },
      {
        max: 255,
        message: "户名最长不可超过255个字符",
        trigger: "blur",
      },
    ],
    bankName: [
      {
        required: true,
        message: "开户银行名称必填",
        trigger: "blur",
      },
      {
        max: 255,
        message: "开户银行名称最长不可超过255个字符",
        trigger: "blur",
      },
    ],
  };
}
